<template>
    <div class="content">
        <!-- <img src="@/static/images/img/banner.png" alt="" /> -->
        <!-- <banner /> -->
        <!-- <aboutSys style="display: none" /> -->
        <!-- <authorization_data /> -->
        <!-- <div class="fnas_count">
            <div class="title">数据每两小时更新一次</div>
            <div class="card_group">
                <div class="card_item">
                    <div class="count_val">{{ data_count.playCount }}</div>
                    <div class="count_label">播放量</div>
                    <i class="iconfont icon-rongqi"></i>
                </div>
                <div class="card_item">
                    <div class="count_val">{{ data_count.diggCount }}</div>
                    <div class="count_label">点赞量</div>
                    <i class="iconfont icon-rongqi"></i>
                </div>
                <div class="card_item">
                    <div class="count_val">{{ data_count.shareCount }}</div>
                    <div class="count_label">分享量</div>
                    <i class="iconfont icon-rongqi"></i>
                </div>
                <div class="card_item">
                    <div class="count_val">{{ data_count.commentCount }}</div>
                    <div class="count_label">评论数</div>
                    <i class="iconfont icon-rongqi"></i>
                </div>
            </div>
        </div> -->
        <fans :fans_data="fans_data" />
        <!-- <indexData />
        <videoData />
        <goodsData />
        <creativeData /> -->
    </div>
</template>
<script>
import { getFanportrait, getAuthorizedUsersCount } from "@/api/index";
import authorization_data from "@/components/index/authorization_data.vue";
import aboutSys from "@/components/index/aboutSys.vue";
import fans from "@/components/index/fans_data.vue";
import banner from "@/components/index/banner.vue";
// import indexData from "../../components/index/index-data.vue";
// import videoData from "../../components/index/video-data.vue";
// import goodsData from "../../components/index/goods-data.vue";
// import creativeData from "../../components/index/creative-data.vue";
// import { indexdata } from '../../api/index.js'
export default {
    components: {
        // indexData,
        // aboutSys,
        // banner,
        fans,
        // authorization_data,
    },
    data() {
        return {
            deviceDistributions: [], //性别分布
            fans_data: "",
            data_count: {
                playCount: 0, //播放量
                diggCount: 0, //点赞
                forwardCount: 0, //转发数
                downloadCount: 0, //下载数
                commentCount: 0, //评论数
            },
        };
    },
    mounted() {},
    created() {
        getFanportrait().then((res) => {
            if (res.code !== 200) return this.$message.error("获取数据失败");
            this.fans_data = res.data;
        });
        getAuthorizedUsersCount().then((res) => {
            if (res.code == 200) {
                this.data_count = res.data;
            }
        });
        // console.log(indexdata);
        // indexdata.getGruopData().then((res) => {
        //   console.log(res);
        // });
    },
    methods: {
        async getFanportrait() {
            // 获取画像数据
            let res = await getFanportrait();
            console.log(res);
        },
    },
};
</script>

<style lang="less" scoped>
.fnas_count {
    background-color: #fff;
    margin-bottom: 10px;
    padding: 20px;
    .title {
        padding: 0 10px;
    }
    .card_group {
        display: flex;
        // justify-content: space-around;
        margin-top: 10px;
        padding: 10px 0;
    }
    .card_item {
        width: 240px;
        padding: 10px;
        box-shadow: 0 0 10px #f3f3f3;
        border-radius: 4px;
        margin-right: 40px;
        padding: 20px;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;
        transition: transform linear 0.1s;
        &:hover {
            box-shadow: 0 10px 20px #f3f3f3;
            transform: scale(1.2);
            .iconfont {
                transition: opacity linear 0.1s;
                opacity: 1;
            }
        }
        .iconfont {
            position: absolute;
            right: 20px;
            top: 20px;
            opacity: 0.3;
        }
    }
    .count_label {
        margin-top: 10px;
    }
    .count_val {
        font-size: 20px;
        font-weight: bold;
    }
}
img {
    width: 100%;
    height: 100px;
    margin: auto;
    display: block;
    object-fit: cover;
    cursor: pointer;
    margin-bottom: 20px;
}
</style>